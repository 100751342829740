.yellow-text {
  color: var(--yellow_color) !important;
}
.primary-text {
  color: var(--primary_color) !important;
}
.secondary-text {
  color: var(--secondary_color);
}
.tertiary-text {
  color: var(--tertiary_color);
}
.neutral-text {
  color: var(--neutral_color) !important;
}
.success-text {
  color: var(--success_color);
}
.white-text {
  color: var(--white_color);
}
.lightgray-text {
  color: var(--lightgray_color);
}
.gray-text {
  color: var(--gray_color) !important;
}
.gray-text-3 {
  color: var(--gray_color3) !important;
}
.gray-text-5 {
  color: var(--gray_color5) !important;
}
.black_text {
  color: var(--black_color);
}
.green_text {
  color: var(--green_color) !important;
}
.red_text {
  color: var(--red_color);
}

.yellow_text {
  color: var(--yellow_color);
}

.primary-bg {
  background-color: var(--primary_color) !important;
}
.secondary-bg {
  background-color: var(--secondary_color) !important;
}
.tertiary-bg {
  background-color: var(--tertiary_color) !important;
}
.green-bg {
  background-color: var(--green_color) !important;
}
.red-bg {
  background-color: var(--red_color) !important;
}
.yellow-bg {
  background-color: var(--yellow_color) !important;
}
.white-bg {
  background-color: var(--white_color) !important;
}

.secondary-text-hover {
  &:hover {
    color: var(--secondary_color);
  }
}
