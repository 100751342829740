body {
  background-color: var(--success_color);
}

ul {
  padding-left: 0px;
  margin-bottom: 0px;
}

.cursor-pointer {
  cursor: pointer;
}

app-footer {
  display: flex;
  position: absolute;
  right: 0;
  bottom: -50px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: var(--white_color);
}

::-webkit-scrollbar {
  width: 8px;
  background-color: var(--white_color);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--gray_color3);
}

.found-icon {
  font-size: 45px;
  color: var(--gray_color3);
}

.break-word {
  word-break: break-word;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

b,
strong {
  font-weight: 500;
}

.back-arrow {
  font-size: 15px;
  font-weight: 500;
  color: var(--gray_color) !important;

  .material-symbols-outlined {
    position: relative;
    top: 2px;
    font-size: 15px;
    left: 5px;
  }
}

.bs-chevron {
  color: var(--primary_color);
}

.form-control:disabled {
  color: #9b9b9b;
}

.sticky-header {
  position: sticky;
  top: 10px;
  margin-bottom: 35px;
}

.header-heading {
  background-color: var(--primary_color);
  padding-bottom: 10px;

  @media only screen and (max-width: 991px) {
    padding-top: 15px;
    padding-left: 10px !important;
  }

  h2 {
    color: var(--white_color);
    font-size: 32px;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    line-height: 38px;

    @media only screen and (max-width: 991px) {
      display: inline-block;
      margin: auto !important;
      font-size: 22px;
      font-weight: 500;
      padding-left: 20px;
    }
  }
}

.view_btn_table {
  border-radius: 8px;
  background: #f3f5f7;
  color: #0d345b;
  margin-top: -5px;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
  outline: none;
  width: 83px;
  padding: 4px 8px;
  text-align: center;
  border: 0px;
}

.active_btn_table {
  border-radius: 6px;
  padding: 2px 5px;
  border: 1px solid #d0d5dd;
  background: #fff;
  color: #344054;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-top: -5px;
  width: max-content;
}

.search_box {
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.05);
  max-width: 292px;
  display: flex;
  padding: 8px 15px;
  align-items: center;
  @media (max-width:991px) {
    width: 100%;
    max-width: 100%;
  }

  input {
    border: none;
    width: 100%;
    margin-left: 12px;
    outline: none;
  }
}

.pop-up {
  display: flex;
  background-color: #0d345b;
  background-color: #0d345bb3;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}

.pop-up .dialog-container {
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: var(--color-light-yellow);
  min-width: 50%;
  height: max-content;
  box-shadow:
    0 16px 24px 2px #00000024,
    0 6px 30px 5px #0000001f,
    0 8px 10px -7px #0006;
  padding: 30px;
  border-radius: 10px;
}

.pop-up .close {
  position: absolute;
  top: 5px;
  right: 5px;
}

.pop-up[_ngcontent-gmx-c11] .close[_ngcontent-gmx-c11] button {
  border: none;
  background: none;
}

@media (min-width: 768px) {
  .pop-up .modal-container {
    width: calc(100% - 1rem);
    height: max-content;
  }
}

@media (min-width: 992px) {
  .pop-up .modal-container {
    width: calc(100% - 5rem);
    height: max-content;
  }
}

@media (min-width: 1200px) {
  .pop-up .modal-container {
    width: calc(100% - 15rem);
    height: max-content;
  }
}

@media (min-width: 1400px) {
  .pop-up .modal-container {
    width: calc(100% - 25rem);
    height: max-content;
  }
}

.close-button {
  display: inline-block;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.close-button:hover {
  background-color: #f0f0f0;
}

.close-icon {
  fill: none;
  stroke: #626366;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

h3.mat-typography.modal-title {
  padding-bottom: 13px;
  font-size: 28px;
  font-family: var(--primary-font);
  font-weight: 400;
}

.ngx-mat-timepicker-dialog>mat-dialog-container [mat-dialog-content] mat-toolbar.timepicker-header {
  position: static;
  --mat-toolbar-container-background-color: var(--primary_color);
}

.ngx-mat-timepicker-dialog {
  .mat-mdc-button.mat-accent {
    --mdc-text-button-label-text-color: var(--primary_color) !important;
  }

  .mat-toolbar.mat-accent {
    --mat-toolbar-container-background-color: var(--primary_color) !important;
    ;
  }
}

.mobile-button {
  display: none;
}

@media (max-width: 991px) {
  .table-section {
    overflow: scroll;
  }

  .mobile-button {
    display: block;
    margin-right: 15px;
    margin-top: 5px;
  }
}

@media (max-width: 991px) {
  img.logo-img {
    margin-left: 15px;
    width: 140px;
    height: 42px;
    object-fit: cover;
  }
}