:root {
  --color-primary: #0d345b;
  --color-secondary: #00c2c9;
  --color-success: #80e315;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-danger: #d60b0b;
  --color-warning: orange;
  --color-orange-2: #ffce75;
  --color-medium: #939598;
  --color-medium-light: #e6e7e8;
  --color-medium-dark: #626366;
  --color-light-yellow: #f0efe9;

  --primary-font: "Bebas Neue", cursive;
  --secondary-font: "Proxima Nova", Georgia, sans-serif;
}
