.sticky-top {
  z-index: 9;
}
.border-top {
  border-color: var(--color-success) !important;
}
.modal-title {
  padding-bottom: 13px !important;
  font-size: 28px !important;
  font-family: var(--primary-font) !important;
  font-weight: 400 !important;
}
