/******************************btn*******************/
button {
  // font-size: 16px;
  cursor: pointer;
  background: none;
}

.button-group-inline {
  button {
    margin-right: 8px;
    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.button-right-align {
  display: flex;
  justify-content: flex-end;
  @media (max-width: 767px) {
    justify-content: center;
    flex-wrap: wrap;
    button {
      margin-bottom: 8px;
    }
  }
}

.button-left-align {
  display: flex;
  justify-content: flex-start;
  @media (max-width: 767px) {
    justify-content: center;
    flex-wrap: wrap;
    button {
      margin-bottom: 8px;
    }
  }
}

.button-center-align {
  display: flex;
  justify-content: center;
  @media (max-width: 767px) {
    justify-content: center;
    flex-wrap: wrap;
    button {
      margin-bottom: 8px;
    }
  }
}

.form-group-g {
  button.icon16 {
    svg {
      fill: var(--color-white);
      width: 16px;
      height: 16px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
  input.icon16 {
    svg {
      fill: var(--color-white);
      width: 16px;
      height: 16px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

// new css for global
.btn_global {
  display: inline-block;
  background: var(--color-success);
  color: var(--color-primary);
  border-radius: 32px;
  padding: 8px 25px;
  font-size: 18px;
  font-family: var(--primary-font);
  min-width: 150px;
  text-align: center;
}
.btn_global_w {
  display: inline-block;
  background: var(--color-white);
  color: var(--color-primary);
  border-radius: 32px;
  padding: 8px 25px;
  font-size: 18px;
  font-family: var(--primary-font);
}
// file upload btn

input[type="file"] {
  position: fixed;
  right: 100%;
  bottom: 100%;
}

// file upload btn end here
