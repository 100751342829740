.loginUser-dropdown {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 56px;
  position: relative;

  svg {
    width: 25px;
    height: 25px;
    fill: #545a77;
    cursor: pointer;
    margin-left: 20px;
  }

  img {
    margin-right: 20px;
  }
}

.notification {
  ul {
    li {
      margin-bottom: 1px;
      strong {
        color: var(--color-primary);
        padding: 7px 0;
        display: block;
        border-bottom: solid 1px var(--color-medium-light);
        margin-bottom: 10px;
        text-transform: uppercase;
        font-size: 14px;
      }

      a.unread-notification {
        background-color: var(--color-medium-light);
      }
    }
  }
}

.hidden-toggleDropdown {
  position: absolute;
  right: 0;
  padding: 10px;
  border: solid 1px #ccc;
  background: #fff;
  margin-top: 10px;
  min-width: 160px;
  border-radius: 5px;
  top: 36px;
  ul {
    margin-bottom: 0;
    li {
      a {
        color: var(--color-black);
        padding: 5px;
        display: block;
        &:hover {
          color: var(--color-success);
        }
      }
    }
  }
}
