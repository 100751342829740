* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.cursor-pointer {
  cursor: pointer;
}
.display-flex {
  display: flex;
  align-items: center;
}
/* padding, outline, margin and padding */
.padding0 {
  padding: 0 !important;
}
.pt0 {
  padding-top: 0 !important;
}
.pb0 {
  padding-top: 0 !important;
}
.pr15 {
  padding-right: 15px !important;
}

.no-margin {
  margin: 0 !important;
}
.no-margin-top {
  margin-top: 0 !important;
}
.no-margin-left {
  margin-left: 0 !important;
}
.no-margin-right {
  margin-right: 0 !important;
}
.no-margin-bottom {
  margin-bottom: 0 !important;
}
.no-padding {
  padding: 0;
}
.no-padding-top {
  padding-top: 0 !important;
}
.no-padding-left {
  padding-left: 0 !important;
}
.no-padding-right {
  padding-right: 0 !important;
}
.no-padding-bottom {
  padding-bottom: 0 !important;
}
.no-border {
  border: none;
}
.no-outline {
  outline: none;
}
.pv-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.pv-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pt-2 {
  padding-top: 2rem;
}
.mt-10 {
  margin-top: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mt-15 {
  margin-top: 15px;
}

.clearfix:after,
.clearfix:before {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  // zoom: 1;
  clear: both;
}
.relative {
  position: relative;
}

.net-text-left {
  text-align: left !important;
}
.net-text-right {
  text-align: right !important;
}
.net-text-center {
  text-align: center !important;
}

.net-container-full {
  width: 100%;
  margin: 0 auto;
}
.net-container {
  width: 1170px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

/* button */

/****css grid****/
.s1,
.s2,
.s3,
.s4,
.s5,
.s6,
.s7,
.s8,
.s9,
.s10,
.s11,
.s12,
.sm1,
.sm2,
.sm3,
.sm4,
.sm5,
.sm6,
.sm7,
.sm8,
.sm9,
.sm10,
.sm11,
.sm12,
.m1,
.m2,
.m3,
.m4,
.m5,
.m6,
.m7,
.m8,
.m9,
.m10,
.m11,
.m12,
.l1,
.l2,
.l3,
.l4,
.l5,
.l6,
.l7,
.l8,
.l9,
.l10,
.l11,
.l12 {
  float: left;
  padding-left: 15px;
  padding-right: 15px;
}
/*****extra small size***/
@media screen and (max-width: 767px) {
  .net-container {
    width: 100%;
  }

  .hidden-xs {
    display: none;
  }
  .s1 {
    width: 8.333%;
  }
  .s2 {
    width: 16.666%;
  }
  .s3 {
    width: 25%;
  }
  .s4 {
    width: 33.33%;
  }
  .s6 {
    width: 50%;
  }
  .s12 {
    width: 100%;
  }
  .s5 {
    width: 41.66%;
  }
  .s7 {
    width: 58.33%;
  }
  .s8 {
    width: 66.66%;
  }
  .s9 {
    width: 75%;
  }
  .s10 {
    width: 83.33%;
  }
  .s11 {
    width: 91.66%;
  }
}
/*****small size***/
@media (min-width: 768px) {
  .net-container {
    width: 750px;
  }

  .hidden-sm {
    display: none !important;
  }
  .sm12 {
    width: 100%;
  }
  .sm11 {
    width: 91.66666667%;
  }
  .sm10 {
    width: 83.33333333%;
  }
  .sm9 {
    width: 75%;
  }
  .sm8 {
    width: 66.66666667%;
  }
  .sm7 {
    width: 58.33333333%;
  }
  .sm6 {
    width: 50%;
  }
  .sm5 {
    width: 41.66666667%;
  }
  .sm4 {
    width: 33.33333333%;
  }
  .sm3 {
    width: 25%;
  }
  .sm2 {
    width: 16.66666667%;
  }
  .sm1 {
    width: 8.33333333%;
  }
}
/*****medium size***/

@media (min-width: 992px) {
  .net-container {
    width: 970px;
  }
  .hidden-md {
    display: none;
  }
  .m1 {
    width: 8.333%;
  }
  .m2 {
    width: 16.666%;
  }
  .m3 {
    width: 25%;
  }
  .m4 {
    width: 33.33%;
  }
  .m6 {
    width: 50%;
  }
  .m12 {
    width: 100%;
  }
  .m5 {
    width: 41.66%;
  }
  .m7 {
    width: 58.33%;
  }
  .m8 {
    width: 66.66%;
  }
  .m9 {
    width: 75%;
  }
  .m10 {
    width: 83.33%;
  }
  .m11 {
    width: 91.66%;
  }
}
/*****large size***/
@media screen and (min-width: 1200px) {
  .net-container {
    width: 1100px;
  }
  .hidden-lg {
    display: none;
  }
  .l1 {
    width: 8.333%;
  }
  .l2 {
    width: 16.666%;
  }
  .l3 {
    width: 25%;
  }
  .l4 {
    width: 33.33%;
  }
  .l6 {
    width: 50%;
  }
  .l12 {
    width: 100%;
  }
  .l5 {
    width: 41.66%;
  }
  .l7 {
    width: 58.33%;
  }
  .l8 {
    width: 66.66%;
  }
  .l9 {
    width: 75%;
  }
  .l10 {
    width: 83.33%;
  }
  .l11 {
    width: 91.66%;
  }
}
