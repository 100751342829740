html,
body {
  font-family: var(--secondary-font);
  font-size: 16px;
  color: var(--color-primary);
  overflow: visible !important;
  background-color: #f0efe8;
}

p {
  margin-bottom: 10px;
  line-height: 26px;
  font-family: var(--secondary-font);
  color: var(--color-primary);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-primary);
}
.mat-typography {
  h2 {
    font-size: 30px;
    @media(max-width:991px) {
      font-size: 24px;
    }
  }
}
a,
a:hover {
  text-decoration: none;
}

.color-medium-light {
  background: var(--color-medium-light);
}

.border-top {
  border-top: solid 1px var(--color-medium-light);
  margin-left: -15px;
  margin-right: -15px;
  clear: both;
}
.title3 {
  font-size: 20px;
}
