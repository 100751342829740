html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;

  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

html,
body {
  height: 100%;
  padding: 0 !important;
  margin: 0 !important;
}

img {
  max-width: 100%;
}

ul {
  list-style: none;
}

a:focus,
a:hover {
  text-decoration: underline;
  outline: 0;
}
::placeholder {
  color: var(--color-medium);
}
:-ms-input-placeholder,
:-ms-input-placeholder {
  color: var(--color-medium);
}
