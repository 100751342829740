/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #dfeecf inset !important;
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
}
.red-star {
  color: var(--color-danger);
  margin-left: 3px;
  font-size: 18px;
}
.green-star {
  color: var(--color-success);
  margin-left: 3px;
  font-size: 18px;
}
.form-controlGlobal {
  width: 100%;
  height: 43px;
  padding: 10px 18px;
  font-size: 15px;
  line-height: 1.42857143;
  background-color: var(--color-white);
  border: solid 1px;
  border-color: var(--color-medium);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  margin-bottom: 15px;
}
textarea {
  resize: none;
}
input,
select {
  width: 100%;
  height: 100%;
}
input:focus {
  outline: none;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
.form-group-g {
  margin-bottom: 15px;
  float: left;
  width: 100%;
  label {
    color: var(--color-primary);
  }
}

.form-control-g {
  border: solid 1px var(--color-medium-light);
  padding: 5px 10px;
  width: 100%;
  height: 45px;
  background-color: var(--color-white);
  border-radius: 10px;
  color: var(--color-primary);
  input,
  select {
    font-size: 14px;
  }
}
.error {
  color: var(--color-danger);
  font-size: 14px;
}
.form-error {
  border: solid var(--color-success) 2px !important;
}

.invalid,
.invalid-blue {
  color: var(--color-white);
  margin-top: -3px;
  margin-bottom: 0;
  padding: 4px 5px 0;
  font-size: 12px;
  position: relative;
  z-index: 0;
  line-height: normal;
}

.invalid-blue {
  color: var(--color-primary) !important;
}

select {
  background: var(--color-white) url(../assets/icons/down-arrow.png) no-repeat;
  background-position: 97% 60%;
  background-size: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select::-ms-expand {
  display: none;
}
.v-a-m {
  display: flex;
  align-items: center;
}
label.label-H {
  min-height: 47px;
  @media (max-width: 767px) {
    min-height: auto !important;
  }
}
/******common firm*****/
.Form {
  .labelForm {
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }
    label {
      width: 300px;
      margin-right: 15px;
    }
  }
}
.loginHeading {
  color: var(--color-white);
  text-align: center;
  h1 {
    font-family: var(--primary-font);
    font-size: 35px;
    font-weight: 400;
    color: var(--color-white) !important;
  }
  p {
    margin-bottom: 0;
    color: var(--color-white) !important;
  }
}

.field_label {
  font-family: var(--primary-font);
  font-weight: 400;
  margin-left: 10px;
}

// custom radio button

.custom-radios {
  .radio {
    display: inline-block;
  }

  input[type="radio"] {
    display: none;

    + label {
      color: #333;
      font-family: Arial, sans-serif;
      font-size: 14px;

      span {
        display: inline-block;
        width: 40px;
        height: 40px;
        margin: -1px 4px 0 0;
        vertical-align: middle;
        cursor: pointer;
        border-radius: 50%;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
        background-repeat: no-repeat;
        background-position: center;
        text-align: center;
        line-height: 44px;

        img {
          opacity: 0;
          transition: all 0.3s ease;
        }
      }
    }

    &.color-1 + label span {
      background-color: var(--color-success);
    }

    &:checked + label span img {
      opacity: 1;
    }
  }
}

// end here

// custom checkbox

.custom-checkbox {
  cursor: pointer;
  display: block;
  font-size: 16px;
  line-height: 26px;
  margin: 0 0 20px;
  padding: 0 0 0 40px;
  position: relative;
}

.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox span.checkbox {
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 26px;
  margin: 0px;
  position: absolute;
  left: 0;
  top: 0px;
  width: 26px;
}

.custom-checkbox input[type="checkbox"]:checked + span.checkbox {
  background: var(--color-success);
  text-align: center;
}

.custom-checkbox input[type="checkbox"]:checked + span.checkbox:before {
  // content: '+';
  // color: #fff;
  content: "";
  background-image: url(/assets/svgs/check-line.png);
  width: 14px;
  height: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 9999;
}

// end here
