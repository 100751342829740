:root {
  --primary_color: #0d345b;
  --secondary_color: #ef6229;
  --tertiary_color: #f3f5f7;
  --success_color: #eaeaea;
  --neutral_color: #323232;
  --white_color: #ffffff;
  --lightgray_color: #ededed;
  --gray_color: #6b778c;
  --gray_color1: #eeeeee;
  --gray_color2: #505050;
  --gray_color3: #a0a0a0;
  --gray_color4: #efefef;
  --gray_color5: #7e7e7e;
  --black_color: #000000;
  --green_color: #198754;
  --red_color: #dc3545;
  --yellow_color: #fea30e;
  --color-primary: #0d345b;
  --color-secondary: #00c2c9;
  --color-success: #80e315;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-danger: #d60b0b;
  --color-warning: orange;
  --color-orange-2: #ffce75;
  --color-medium: #939598;
  --color-medium-light: #e6e7e8;
  --color-medium-dark: #626366;
  --color-light-yellow: #f0efe9;

  --primary-font: "Bebas Neue", cursive;
  --secondary-font: "Proxima Nova", Georgia, sans-serif;
  --tertiary-font: font-family: "Inter", sans-serif;
}
