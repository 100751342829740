//table responsive

.table-responsive {
  @media (max-width: 767px) {
    overflow: auto;
  }
  .table-body {
    @media (max-width: 767px) {
      width: 700px !important;
    }
  }
}

/*****user page  table*****/

.ngx-datatable .datatable-body .datatable-body-row > div {
  border-bottom: solid 1px var(--color-medium-light);
  transition: all 1s;
}
.ngx-datatable.material .datatable-footer {
  border-top: 0 !important;
}
.ngx-datatable.material
  .datatable-body
  .datatable-body-row
  .datatable-body-cell {
  color: var(--color-primary) !important;
}
.ngx-datatable.fixed-header
  .datatable-header
  .datatable-header-inner
  .datatable-header-cell {
  font-size: 14px !important;
  color: var(--color-primary) !important;
  font-weight: bold;
}
.ngx-datatable.material .datatable-footer .datatable-pager a:hover {
  background-color: var(--color-success);
}
.ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: var(--color-primary);
  color: var(--color-white);
}
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection)
  .datatable-body-row:hover
  .datatable-row-group {
  // background-color: var(--color-medium-light);
  transition: all 2s !important;
  box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
}

/****btn*****/
.Table-download-btn {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
  input {
    background: var(--color-medium-dark);
    color: var(--color-white);
    padding: 10px;
    flex-basis: 10%;
    border-radius: 5px;
    margin-left: 5px;
    cursor: pointer;
    transition: all 1s;
    &:hover {
      background: var(--color-medium);
    }
  }
}

.grid {
  padding-bottom: 25px;

  table {
    width: 100%;
    text-align: left;
    border: 0px !important;
    margin-bottom: 0;
    border-collapse: separate !important;
    border-spacing: 0 20px;
    font-size: 13px;
    //tr border radius
    td:first-child,
    th:first-child {
      border-radius: 10px 0 0 10px;
    }
    td:last-child,
    th:last-child {
      -moz-border-radius: 0 10px 10px 0;
      -webkit-border-radius: 0 10px 10px 0;
      border-radius: 0 10px 10px 0;
    }

    thead {
      tr {
        background-color: var(--color-primary);
        color: var(--color-white);
        th {
          font-weight: normal;
          position: relative;
          img {
            width: 10px;
            margin-left: 5px;
          }
          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 55px;
            width: 100%;
            height: 1px;
            background-color: var(--color-success);
            border-radius: 5px 0 0 5px;
            @media (max-width: 768px) {
              height: 0;
            }
          }
        }
      }
    }
    tbody {
      tr:hover {
        box-shadow: 0 6px 8px rgb(0 0 0 / 15%);
      }
    }
    tr {
      background-color: var(--color-white);
      cursor: pointer;
    }
    th {
      padding: 15px;
      border: 0;
      vertical-align: middle;
    }
    td {
      padding: 15px;
      border-right: solid 1px #f0efe9;
      vertical-align: middle;
      position: relative;

      &:last-child {
        border-right: none !important;
      }
    }
  }
}
