@font-face {
  font-family: "Bebas Neue";
  src: url("/assets/fonts/bebas-neue/BebasNeue.woff");
}
@font-face {
  font-family: "BebasNeue Bold";
  src: url("/assets/fonts/BebasNeue Bold.woff");
}
@font-face {
  font-family: "BebasNeue Light";
  src: url("/assets/fonts/bebas-neue/BebasNeue Light.woff");
}
@font-face {
  font-family: "BebasNeue Regular";
  src: url("/assets/fonts/bebas-neue/BebasNeue Regular.woff");
}
@font-face {
  font-family: "BebasNeue Thin";
  src: url("/assets/fonts/bebas-neue/BebasNeue Thin.woff");
}

// Proxima Font
// below comment font is old
@font-face {
  font-family: "Proxima Nova";
  src: url("/assets/fonts/proxima-nova//Proxima Nova Alt Regular-webfont.woff")
    format("woff");
  font-style: normal;
}
@font-face {
  font-family: "Proxima Nova Thin";
  src: url("/assets/fonts/proxima-nova/Proxima Nova Thin-webfont.woff")
    format("woff");
  font-style: normal;
}
