.modal-body {
  position: relative;
  padding: 15px;
}
.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}
.modal-title {
  padding-bottom: 13px;
  font-size: 28px;
  font-family: var(--primary-font);
  font-weight: 400;
}

.pop-up-btn {
  button {
    margin: 2px;
  }
}

.body-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
}
.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
