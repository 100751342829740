.box50 {
  width: 50%;

  @media (max-width: 767px) {
    width: 100% !important;
  }
}
.box70 {
  width: 70%;

  @media (max-width: 767px) {
    width: 100%;
  }
}
.box80 {
  width: 80%;

  @media (max-width: 767px) {
    width: 100%;
  }
}
.box85 {
  width: 85%;
}
.box90 {
  width: 90%;

  @media (max-width: 767px) {
    width: 100%;
  }
}
.box100 {
  width: 100%;
}

.box {
  // min-height: 70vh;
  background: var(--color-white);
  border-radius: 10px;
  box-shadow: 6px 6px 12px rgba(98, 99, 102, 0.2);
  display: flex;
  flex-direction: column;
  padding: 30px;
  margin: 0 auto;

  @media (max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}
