/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@swimlane/ngx-datatable/index.css";
@import "../node_modules/@swimlane/ngx-datatable/themes/material.scss";
@import "../node_modules/@swimlane/ngx-datatable/assets/icons.css";

/****typo grapy***/
@import "./theme/reset", "./theme/typography";

/****css grid***/
@import "./theme/grid";

/****Form***/
@import "./theme/form", "./theme/button", "./theme/box", "./theme/drop-down";

/****Table***/
@import "./theme/table";

/****Popup***/
@import "./theme/popup";

/****Fonts***/
@import "./theme/fonts";
@import "theme/_variables";
@import "theme/colors_style";
@import "theme/material_style";
@import "theme/bootstrap_style";
@import "theme/custom_style";

main {
  min-height: calc(100vh - 100px - 101px);
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: -1px;
}

.d-block {
  display: block;
}

.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.align-item-center {
  align-items: center;
}
.flex-direction-column {
  flex-direction: column;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}

button:hover {
  cursor: pointer;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 10px 18px;
  line-height: 1.42857143;
  border-radius: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn-default {
  background-color: var(--color-white);
  border-color: var(--color-medium);
}
.btn-outline {
  border-color: var(--color-medium);
}
.btn-primary {
  color: var(--color-white);
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

/** background colors */
.bg-primary {
  background-color: var(--color-primary);
}
.bg-secondary {
  background-color: var(--color-secondary);
}
.bg-danger {
  background-color: var(--color-danger);
}
.bg-success {
  background-color: var(--color-success);
}
.bg-medium {
  background-color: var(--color-medium);
}
.bg-dark {
  background-color: var(--color-black);
}
.bg-light {
  background-color: var(--color-white);
}

/** text colors */
.text-primary {
  color: var(--color-primary);
}
.text-secondary {
  color: var(--color-secondary);
}
.text-danger {
  color: var(--color-danger);
}
.text-success {
  color: var(--color-success);
}
.text-medium {
  color: var(--color-medium);
}
.text-dark {
  color: var(--color-black);
}
.text-light {
  color: var(--color-white);
}

/** border colors */
.border-primary {
  border: solid var(--color-primary) 2px;
  &:focus {
    border: solid var(--color-primary) 2px;
  }
}
.border-secondary {
  border: solid var(--color-secondary) 2px;
  &:focus {
    border: solid var(--color-secondary) 2px;
  }
}
.border-danger {
  border: solid var(--color-danger) 2px;
  &:focus {
    border: solid var(--color-danger) 2px;
  }
}
.border-success {
  border: solid var(--color-success) 2px;
  &:focus {
    border: solid var(--color-success) 2px;
  }
}
.border-medium {
  border: solid var(--color-medium) 2px;
  &:focus {
    border: solid var(--color-medium) 2px;
  }
}
.border-dark {
  border: solid var(--color-black) 2px;
  &:focus {
    border: solid var(--color-black) 2px;
  }
}
.border-light {
  border: solid var(--color-white) 2px;
  &:focus {
    border: solid var(--color-white) 2px;
  }
}
.border-radius-small {
  border-radius: 0.5rem;
}
.border-radius {
  border-radius: 1rem;
}
.border-radius-large {
  border-radius: 2rem;
}

/** outline colors */
.outline-primary {
  outline: solid var(--color-primary) 2px;
  &:focus {
    outline: solid var(--color-primary) 2px;
  }
}
.outline-secondary {
  outline: solid var(--color-secondary) 2px;
  &:focus {
    outline: solid var(--color-secondary) 2px;
  }
}
.outline-danger {
  outline: solid var(--color-danger) 2px;
  &:focus {
    outline: solid var(--color-danger) 2px;
  }
}
.outline-success {
  outline: solid var(--color-success) 2px;
  &:focus {
    outline: solid var(--color-success) 2px;
  }
}
.outline-medium {
  outline: solid var(--color-medium) 2px;
  &:focus {
    outline: solid var(--color-medium) 2px;
  }
}
.outline-dark {
  outline: solid var(--color-black) 2px;
  &:focus {
    outline: solid var(--color-black) 2px;
  }
}
.outline-light {
  outline: solid var(--color-white) 2px;
  &:focus {
    outline: solid var(--color-white) 2px;
  }
}

/* images and link */
.img-fluid,
img {
  max-width: 100%;
  height: auto;
}

/* title and text */
.supper-title {
  font-family: "Bebas Neue", cursive;
  font-size: 2.6rem;
}

/* text */
.text-capitalize {
  text-transform: capitalize;
}
.text-lower {
  text-transform: lowercase;
}
.text-upper {
  text-transform: uppercase;
}
.text-center {
  text-align: center;
}

/** service line health report **/
.service-line-health {
  overflow-x: inherit !important;
  position: relative;
  height: 100%;
  strong {
    width: 20px;
    height: 20px;
    position: relative;
    border-radius: 50%;
    font-size: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: normal;
  }

  strong {
    cursor: pointer;
    span {
      position: absolute;
      padding: 5px 10px;
      width: 130px;
      background: var(--color-primary);
      color: var(--color-white);
      display: block;
      z-index: 9999;
      transition: all 1s;
      opacity: 0;
      margin-left: -55px;
      margin-top: -35px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 90px;
      line-height: 13px;
      font-size: 12px !important;
    }
    &:hover {
      span {
        opacity: 1;
      }
    }
  }
}

.service-line-warning {
  strong {
    background-color: var(--color-warning);
  }
}
.service-line-success {
  strong {
    background-color: var(--color-success);
  }
}
.service-line-danger {
  strong {
    background-color: var(--color-danger);
  }
}
.service-line-medium {
  strong {
    background-color: var(--color-medium);
  }
}

@media (max-width: 767px) {
  .LoginSection {
    width: 100% !important;
  }
  .form-control {
    width: 100%;
  }
  .footer-row {
    flex-direction: column;
    justify-content: center !important;
  }
  .footer-links {
    margin-bottom: 15px;
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
